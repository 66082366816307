<template>
  <b-card
    class="registration"
  >
    <!-- guardian inforamtion  -->
    <b-row>
      <b-col
        md="12"
      >
        <div
          style=""
          class="category-title text-center mb-1 main-space"
        >
          {{ $t('guardianData') }}
        </div>
      </b-col>

      <b-col md="4">
        <g-field
          :value.sync="selectedItem.guardianName"
          label-text="guardianName"
        />
      </b-col>
      <b-col md="4">
        <g-field
          :value.sync="selectedItem.contactMethodName"
          label-text="contanctMethodForRegisterationUpdates"
        />
      </b-col>
      <b-col md="4">
        <g-field
          :value.sync="selectedItem.contactContent"
          :label-text="selectedItem.contactWithMobile ? 'mobile' : 'email'"
        />
      </b-col>
    </b-row>

    <!-- arabic and english Name Section -->
    <b-row
      class="mb-1"
    >
      <b-col
        md="12 mt-2"
      >
        <div
          class="text-center mb-1 main-space category-title"
        >
          {{ $t('studentData') }}
        </div>
      </b-col>

      <!-- arabic name  -->
      <b-col md="6">
        <g-field
          :value.sync="selectedItem.arabicName"
          rules="required"
          label-text="arabicName"
        />
      </b-col>
      <!-- english name  -->
      <b-col md="6">
        <g-field
          ref="englishName"
          :value.sync="selectedItem.englishName"
          label-text="englishName"
        />
      </b-col>
    </b-row>

    <!-- personal inforamtions section -->
    <b-tabs
      v-model="tabIndex"
      content-class="pt-2"
      pills
      fill
    >
      <b-tab
        :title="$t('personalData')"
        :active="tabIndex === studentInfoEnum.personalData"
      >
        <b-card>
          <b-row>
            <!-- birthDate  -->
            <b-col md="3">
              <g-picker
                :value.sync="selectedItem.birthDate"
                label-text="birthDate"
                name="birthDate"
              />
            </b-col>

            <!-- registrationDate  -->
            <b-col md="3">
              <g-picker
                :value.sync="selectedItem.registrationDate"
                label-text="registrationDate"
                name="registrationDate"
              />
            </b-col>

            <!-- identityNumber -->
            <b-col md="3">
              <g-field
                :value.sync="selectedItem.identityNumber"
                label-text="IDNumber"
              />
            </b-col>

            <!-- mobileNumber -->
            <b-col md="3">
              <g-field
                id="phone"
                rules="max:10|min:10|required"
                :value.sync="selectedItem.mobileNumber"
                name="scalesCode"
                type="number"
                label-text="phone"
              />
            </b-col>

            <!-- noorId  -->
            <b-col md="3">
              <g-field
                :value.sync="selectedItem.noorId"
                label-text="noorId"
                :rules="{ required: selectedItem.registeredInNoor }"
              />
            </b-col>

            <!-- gender -->
            <b-col md="3">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.gender"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="gender"
                  field="select"
                  name="gender"
                  :options="genders"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- nationality -->
            <b-col md="3">
              <b-form-group>
                <g-field
                  field="select"
                  label="arabicName"
                  label-text="nationality"
                  name="nationality"
                  rules="required"
                  :value.sync="selectedItem.nationalityId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :options="nationalities"
                />
              </b-form-group>
            </b-col>

            <!-- nameOfTheLastSchool  -->
            <b-col md="3">
              <g-field
                :value.sync="selectedItem.nameOfTheLastSchool"
                label-text="nameOfTheLastSchool"
              />
            </b-col>

            <!-- noor registeration check -->
            <b-col md="2">
              <label
                class="main-font-size"
                style="margin-bottom: 7px"
                for="registeredInNoor"
              >
                {{ $t("registeredInNoor") }}
              </label>
              <b-form-group>
                <b-form-checkbox
                  v-model="selectedItem.registeredInNoor"
                  class="custom-control-primary"
                />
              </b-form-group>
            </b-col>

            <!-- bus subscription -->
            <b-col
              md="4"
              class="mb-50"
            >
              <label class="main-font-size">
                {{ $t("transferRegistration") }}
              </label>
              <b-form-radio-group
                v-model="selectedItem.hasBus"
                button-variant="outline-primary"
                :options="optionsRadio"
                size="md"
                name="radios-btn-default"
              />
            </b-col>

            <!-- address of bus land -->
            <b-col
              v-show="selectedItem.hasBus === true"
              md="3"
            >
              <g-field
                :value.sync="selectedItem.detailedAddress"
                label-text="detailedAddress"
                :rules="{ required: selectedItem.hasBus }"
              />
            </b-col>

            <!-- bus Subscription Category   -->
            <b-col
              v-show="selectedItem.hasBus === true"
              md="3"
            >
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.busSubscriptionCategory"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="busSubscriptionCategory"
                  field="select"
                  name="busSubscriptionCategory"
                  :options="busSubscriptionCategory"
                  label="arabicName"
                  :rules="{ required: selectedItem.hasBus }"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- notes -->
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('notes')">
                <b-form-textarea
                  id="textarea"
                  v-model="selectedItem.notes"
                  label-text="Notes"
                  rows="3"
                  max-rows="6"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>

      <!-- educational inforamtions section -->
      <b-tab
        :title="$t('stageData')"
        :active="tabIndex === studentInfoEnum.educationalData"
      >
        <b-card>
          <b-row>
            <!-- stage -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  v-model="selectedItem.stageId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  field="select"
                  name="stages"
                  :options="stages"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  label-text="stage"
                  disabled
                  @change="
                    () => {
                      selectedItem.rowId = null;
                    }
                  "
                />
              </b-form-group>
            </b-col>

            <!-- row -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  v-model="selectedItem.rowId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  field="select"
                  name="row"
                  :options="rows"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  label-text="row"
                  :filter-fn="(item) => item.stageId === selectedItem.stageId"
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- classRoom  -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  v-model="selectedItem.classroomId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  field="select"
                  name="classRoom"
                  :options="classrooms"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  label-text="classRoom"
                  :filter-fn="(item) => item.row === selectedItem.rowId"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>

      <!-- medical and psychological information Section  -->
      <b-tab
        :title="$t('Medicalandpsychologicaldataofthestudent')"
        :active="tabIndex === studentInfoEnum.healthData"
      >
        <b-card>
          <b-row>
            <!-- religion -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.religionId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="religion"
                  field="select"
                  name="religions"
                  :options="religions"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- childLivesWith -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.childLivesWith"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="childLivesWith"
                  field="select"
                  name="childLivesWith"
                  :options="childLivesWith"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- maritalStatusParentsId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.maritalStatusParentsId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="maritalStatusForParents"
                  field="select"
                  name="maritalStatus"
                  :options="maritalStatus"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- roleOfGrandparents -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.roleOfGrandparents"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="roleOfGrandparents"
                  field="select"
                  name="RoleOfGrandparents"
                  :options="roleOfGrandparents"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- illnessHistoryInFamily -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.illnessHistoryInFamily"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="illnessHistoryInFamily"
                  field="select"
                  name="illnessHistoryInFamily"
                  :options="illnessHistoryInFamily"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- suffersFromAnAllergy -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.suffersFromAnAllergy"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="suffersFromAnAllergy"
                  field="select"
                  name="suffersFromAnAllergy"
                  :options="suffersFromAnAllergy"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- describeChild -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.describeChild"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="describeChild"
                  field="select"
                  name="describeChild"
                  :options="describeChild"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- behaviorsWishToGetRidOf -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.behaviorsWishToGetRidOf"
                label-text="behaviorsWishToGetRidOf"
              />
            </b-col>

            <!-- wentToAPsychologistOrBehaviorist -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.wentToAPsychologistOrBehaviorist"
                label-text="heWentToPsychologistOrBehaviorist"
              />
            </b-col>

            <!-- punishmentOfTheChildWhenWrong -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.punishmentOfTheChildWhenWrong"
                label-text="punishmentOfTheChildWhenWrong"
              />
            </b-col>

            <!-- numberOfBrothers  -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.numberOfBrothers"
                label-text="numberOfBrothers"
              />
            </b-col>

            <!-- childArrangement -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.childArrangement"
                label-text="childArrangement"
              />
            </b-col>

            <!-- childSkillsAndTendencies -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.childSkillsAndTendencies"
                label-text="childSkillsAndTendencies"
              />
            </b-col>

            <!-- academicLevelAndAchievement -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.academicLevelAndAchievement"
                label-text="academicLevelAndAchievement"
              />
            </b-col>

            <!-- participatesInGroupGames -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.participatesInGroupGames"
                label-text="participatesInGroupGames"
              />
            </b-col>

            <!-- favoriteSports -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.favoriteSports"
                label-text="favoriteSports"
              />
            </b-col>

            <!-- favoritePlace -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.favoritePlace"
                label-text="favoritePlace"
              />
            </b-col>

            <!-- relationshipWitFriendsAndBrother -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.relationshipWitFriendsAndBrother"
                label-text="relationshipWitFriendsAndBrother"
              />
            </b-col>

            <!-- firstReactionToBeingPressured -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.firstReactionToBeingPressured"
                label-text="firstReactionToBeingPressured"
              />
            </b-col>

            <!-- firstPersonChildTurnsToWhenAngry -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.firstPersonChildTurnsToWhenAngry"
                label-text="firstPersonChildTurnsToWhenAngry"
              />
            </b-col>

            <!-- suffersFromDiseases  -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.suffersFromDiseases"
                label-text="suffersFromDiseases"
              />
            </b-col>

            <!-- healthReasonThatInterferesWithPerformance -->
            <b-col md="4">
              <g-field
                :value.sync="
                  selectedItem.healthReasonThatInterferesWithPerformance
                "
                label-text="healthReasonThatInterferesWithPerformance"
              />
            </b-col>

            <!-- receivesTreatmentPeriodicallyOrContinuously -->
            <b-col md="4">
              <g-field
                :value.sync="
                  selectedItem.receivesTreatmentPeriodicallyOrContinuously
                "
                label-text="receivesTreatmentPeriodicallyOrContinuously"
              />
            </b-col>

            <!-- repetitiveBehaviour -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.repetitiveBehaviour"
                label-text="repetitiveBehaviour"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-row
      v-if="selectedItem.status === 'waiting'"
    >
      <b-col
        md="12"
        class="d-flex justify-content-end"
      >
        <b-button
          v-permission="'acceptRegistrationRequest'"
          class="mx-1"
          variant="primary"
          @click="accept()"
        >
          <feather-icon
            icon="CheckIcon"
            size="15"
            class="mr-25"
          />
          {{ $t('acceptStudentRegistration') }}
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  guardianRelationships,
  genders, childLivesWith,
  roleOfGrandparents, illnessHistoryInFamily,
  suffersFromAnAllergy, describeChild,
  busSubscriptionCategory
} from '@/libs/acl/Lookups';

export default {
  props: ['id'],
  data() {
    return {
      nationalities: [],
      religions: [],
      maritalStatus: [],
      classrooms: [],
      rows: [],
      stages: [],
      formSubmitedSuccessfully: false,
      selectedItem: {
        arabicName: '',
        englishName: '',
        notes: '',
        stageId: null,
        rowId: null,
        classroomId: null,
        hasBus: false,
        status: 1,
        registeredInNoor: false,
        contactWithMobile: true
      },
      guardianRelationships: guardianRelationships,
      genders: genders,
      childLivesWith: childLivesWith,
      roleOfGrandparents: roleOfGrandparents,
      describeChild: describeChild,
      illnessHistoryInFamily: illnessHistoryInFamily,
      suffersFromAnAllergy: suffersFromAnAllergy,
      busSubscriptionCategory: busSubscriptionCategory,
      tabIndex: 0,
      studentInfoEnum: {
        personalData: 0,
        educationalData: 1,
        healthData: 2
      },
      helper: {
        guardianMobile: null,
        guardianEmail: null
      }

    };
  },
  computed: {
    optionsRadio() {
      return [
        { text: this.$t('yes'), value: true },
        { text: this.$t('no'), value: false },
      ];
    },
  },

  mounted() {
    this.getObject();
    this.loadData();
  },

  methods: {
    getObject() {
      this.get({ url: `RegistrationRequests/${this.id}` }).then((data) => {
        this.selectedItem = data;
        this.selectedItem.contactMethodName = this.selectedItem.contactWithMobile ? this.$t('mobile') : this.$t('email');
      });
    },

    loadData() {
      this.get({ url: 'Nationalities' }).then((data) => {
        this.nationalities = data;
      });
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
      this.get({ url: 'Rows' }).then((data) => {
        this.rows = data;
      });
      this.get({ url: 'Classrooms/getClassroomsLookupWithEducationlIds' }).then((data) => {
        this.classrooms = data;
      });
      this.get({ url: 'Religions' }).then((data) => {
        this.religions = data;
      });
      this.get({ url: 'MaritalStatuses' }).then((data) => {
        this.maritalStatus = data;
      });
    },

    registrationDateCheck() {
      if (!this.selectedItem.registrationDate) {
        this.doneAlert({ text: this.$t('registrationDateIsRequired'), type: 'warning' });
        return false;
      }
      return true;
    },

    birthDateCheck() {
      if (!this.selectedItem.birthDate) {
        this.doneAlert({ text: this.$t('birthDateIsRequired'), type: 'warning' });
        return false;
      }
      return true;
    },

    classCheck() {
      if (!this.selectedItem.classroomId) {
        this.doneAlert({ text: this.$t('classIsRequired'), type: 'warning' });
        return false;
      }
      return true;
    },

    accept() {
      if (!this.classCheck()) {
        this.tabIndex = this.studentInfoEnum.educationalData;
        return;
      }

      // set higri dates
      this.selectedItem.hijriBirthDate = this.convetDatetoHijri(this.selectedItem.birthDate);
      this.selectedItem.hijriRegistrationDate = this.convetDatetoHijri(this.selectedItem.registrationDate);

      this.create({ url: 'RegistrationRequests/AcceptRegistrationRequest', data: this.selectedItem }).then(() => {
        this.doneAlert({ text: this.$t('registrationAccepted') });
        this.backToList();
      });
    },

    backToList() {
      this.$router.push({ name: 'temporaryStudents' });
    },

    convetDatetoHijri(dateToConvert) {
      return new Intl.DateTimeFormat('ar-TN-u-ca-islamic',
        {
          day: 'numeric', month: 'long', weekday: 'long', year: 'numeric'
        }).format(new Date(dateToConvert));
    }
  }
};
</script>

<style>
.registration .nav-link{
  border-color: gray;
  margin: 3px;
}

.registration .category-title {
  background-color: #7367f0;
  color: #FFF;
  padding: 10px;
  border-radius: 5px;
  width: 200px;
}
</style>
